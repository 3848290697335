import React from "react";
import { useNavigate } from "react-router-dom";
import "./modal.css";

const Modal = (props) => {
  const navigate = useNavigate();

  const toggleModal = () => {
    props.statusModal(false);
    navigate("/");
  };

  return (
    <div className="modal3">
      <div className="overlay3"></div>
      <div className="modal-content3">
        <div className="boxMessage3">
          <p className="TextP3">{props.message}</p>
        </div>
        <div>
          <button className="close-modal3" onClick={toggleModal}>
            Volver Al Menú
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
