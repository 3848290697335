import React from "react";

import ImageWall from "../components/imageWall/imageWall";
import Header from "../components/header/header";
import Form from "../components/form/formIndex/form";
import { Helmet } from "react-helmet";

const CreditFirst3 = () => {
  <Helmet>
    <meta charset="utf-8" />
    <link rel="icon" href="%PUBLIC_URL%/logo594menos2.png" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="theme-color" content="#000000" />
    <meta
      name="description"
      content="Web site created using create-react-app"
    />
    <meta
      http-equiv="Content-Security-Policy"
      content="upgrade-insecure-requests"
    />
    <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
    <script
      src="https://kit.fontawesome.com/4dafbd3371.js"
      crossorigin="anonymous"
    ></script>
    <title>3dejunio</title>
  </Helmet>;
  return (
    <ImageWall>
      <Header
        title="Bienvenido a préstamo online"
        text="Para iniciar el proceso por favor ingrese los datos pedidos en pantalla"
      />
      <Form />
    </ImageWall>
  );
};

export default CreditFirst3;
