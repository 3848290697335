import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import env from "react-dotenv";
import axios from "axios";
import "./form.css";

const Form = () => {
  const navigate = useNavigate();
  const captcha = useRef(null);

  const [Cuil, setCuil] = useState("");
  const [monto, setMonto] = useState("");
  const [capcha, setcapcha] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [errorValidation, setErrorValidation] = useState();

  const saveUser = async (Cuil) => {
    if (Cuil.length === 11) {
      try {
        const response = await axios.get(env.REACT_APP_APIHOST_AMECE + Cuil);
        const data = response.data;
        setUserInfo({ data });
      } catch (err) {
        return;
      }
    }
    return;
  };

  useEffect(() => {
    saveUser(Cuil);
  });

  const onChange = () => {
    if (captcha.current.getValue()) {
      setcapcha(true);
    }
  };

  const handleChangeCuil = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Eliminar caracteres no numéricos
    setCuil(value);
  };

  const handleChangeMonto = (e) => {
    const value = e.target.value.replace(/^0+/, ""); // Eliminar ceros iniciales
    setMonto(value);
  };

  const ClickHandler = (e) => {
    e.preventDefault();
    let User = {};
    if (Object.entries(userInfo).length === 0) {
      User = {
        UserRegistration: {
          infoUser: { Cuil: Cuil },
          credito: { Monto_Certificado: monto },
        },
      };
    } else {
      User = {
        UserRegistration: {
          infoUser: { ...userInfo },
          credito: { Monto_Certificado: monto },
        },
      };
    }

    if (Cuil.length < 11 || monto.length < 4) {
      setErrorValidation(true);
    } else {
      setErrorValidation(false);
    }

    if (capcha === true && errorValidation === false) {
      navigate("/Seleccion_Credito", { state: User });
    }
  };

  return (
    <div className="cardForm2">
      <div className="boxInputi">
        <i className="fa-regular fa-address-card icon"></i>
        <input
          className="inputForm"
          placeholder="Ingrese su Cuil"
          maxLength={11}
          minLength={11}
          value={Cuil}
          onChange={handleChangeCuil}
        />
      </div>
      {errorValidation && Cuil.length < 11 ? (
        <p className="errorMesage">El cuil debe tener 11 diguitos</p>
      ) : null}

      <div className="boxInputi">
        <i className="fa-solid fa-money-bill icon"></i>
        <input
          className="inputForm"
          placeholder="Monto del certificado a afectar"
          minLength={4}
          value={monto}
          onChange={handleChangeMonto}
        />
      </div>
      {errorValidation && monto.length < 4 ? (
        <p className="errorMesage">
          El Monto certificado a otorgar debe tener un minimo de 4 diguitos
        </p>
      ) : null}

      <div className="box">
        <ReCAPTCHA
          ref={captcha}
          sitekey={env.REACT_APP_pruebaCapcha}
          onChange={onChange}
        />
      </div>

      <div className="boxButton">
        <button onClick={ClickHandler} className="Button">
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Form;
