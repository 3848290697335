import React, { useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import "./signatureCanvas.css";

const Canvas = (props) => {
  const sigCanvas = useRef();
  const [userfullimage, setUserFulImage] = useState(props.userimage);
  const navigate = useNavigate();

  const ClickPagefinal = () => {
    const firmaBase64 = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");

    const user = {
      UserRegistration: {
        ...userfullimage.UserRegistration,
        firma: {
          Firma_url: firmaBase64,
        },
      },
    };

    navigate("/Credito_Final", { state: user });
  };

  const Clickdelete = () => sigCanvas.current.clear();

  return (
    <div className="boxCanvas">
      <SignatureCanvas
        ref={sigCanvas}
        penColor="#00a8f3"
        backgroundColor="rgba(255, 255, 255)"
        canvasProps={{
          className: "canvas",
        }}
      />
      <div className="boxButton6">
        <button onClick={Clickdelete} className="Button6">
          Eliminar
        </button>
        <button onClick={ClickPagefinal} className="Button6">
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Canvas;
