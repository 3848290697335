import "./App.css";
import React from "react";
import { Route, Routes } from "react-router-dom";

import CreditIndex from "./screens/CreditFirt";
import CreditTwo from "./screens/CreditPartTwo";
import CreditThree from "./screens/CreditThree";
import CreditFive from "./screens/CreditFive";
import CreditSix from "./screens/CreditSix";
import CreditFinal from "./screens/CreditFinal";
/* 3 DE JUNIO */
import CreditTwo3 from "./web3deJunio/src/screens/CreditPartTwo";
import CreditFive3 from "./web3deJunio/src/screens/CreditFive";
import CreditFinal3 from "./web3deJunio/src/screens/CreditFinal";
import CreditSix3 from "./web3deJunio/src/screens/CreditSix";
import CreditFirst3 from "./web3deJunio/src/screens/CreditFirt";
import CreditThree3 from "./web3deJunio/src/screens/CreditThree";
/* 3 DE JUNIO */

function App() {
  return (
    <Routes>
      <Route path="/" element={<CreditIndex />} />
      <Route path="/Seleccion_Credito" element={<CreditTwo />} />
      <Route path="/Confirmacion_Info" element={<CreditThree />} />
      <Route path="/Subida_de_fotos" element={<CreditFive />} />
      <Route path="/Firma" element={<CreditSix />} />
      <Route path="/Credito_Final" element={<CreditFinal />} />

      <Route path="/3dejunio" element={<CreditFirst3 />} />
      <Route path="/3dejunio/Seleccion_Credito" element={<CreditTwo3 />} />
      <Route path="/3dejunio/Confirmacion_Info" element={<CreditThree3 />} />
      <Route path="/3dejunio/Subida_de_fotos" element={<CreditFive3 />} />
      <Route path="/3dejunio/Firma" element={<CreditSix3 />} />
      <Route path="/3dejunio/Credito_Final" element={<CreditFinal3 />} />
    </Routes>
  );
}

export default App;
