const ListDataCredits = [
  /*24 Meses*/
  {
    id: 23,
    Monto_Otorgado: "200.000",
    Monto_Depositado: "200.000",
    Cuotas: "24",
    Pago_Mensual: "20037",
  },
  {
    id: 22,
    Monto_Otorgado: "190.000",
    Monto_Depositado: "190.000",
    Cuotas: "24",
    Pago_Mensual: "19035",
  },
  {
    id: 21,
    Monto_Otorgado: "180.000",
    Monto_Depositado: "180.000",
    Cuotas: "24",
    Pago_Mensual: "18033",
  },
  {
    id: 20,
    Monto_Otorgado: "170.000",
    Monto_Depositado: "170.000",
    Cuotas: "24",
    Pago_Mensual: "17031",
  },
  {
    id: 19,
    Monto_Otorgado: "160.000",
    Monto_Depositado: "160.000",
    Cuotas: "24",
    Pago_Mensual: "16029",
  },
  {
    id: 18,
    Monto_Otorgado: "150.000",
    Monto_Depositado: "150.000",
    Cuotas: "24",
    Pago_Mensual: "15028",
  },
  {
    id: 17,
    Monto_Otorgado: "140.000",
    Monto_Depositado: "140.000",
    Cuotas: "24",
    Pago_Mensual: "14026",
  },
  {
    id: 16,
    Monto_Otorgado: "130.000",
    Monto_Depositado: "130.000",
    Cuotas: "24",
    Pago_Mensual: "13024",
  },
  {
    id: 15,
    Monto_Otorgado: "125.000",
    Monto_Depositado: "125.000",
    Cuotas: "24",
    Pago_Mensual: "12523",
  },
  {
    id: 14,
    Monto_Otorgado: "120.000",
    Monto_Depositado: "120.000",
    Cuotas: "24",
    Pago_Mensual: "12022",
  },
  {
    id: 13,
    Monto_Otorgado: "110.000",
    Monto_Depositado: "110.000",
    Cuotas: "24",
    Pago_Mensual: "11020",
  },
  {
    id: 12,
    Monto_Otorgado: "100.000",
    Monto_Depositado: "100.000",
    Cuotas: "24",
    Pago_Mensual: "10018",
  },
  {
    id: 11,
    Monto_Otorgado: "90.000",
    Monto_Depositado: "90.000",
    Cuotas: "24",
    Pago_Mensual: "9016",
  },
  {
    id: 10,
    Monto_Otorgado: "80.000",
    Monto_Depositado: "80.000",
    Cuotas: "24",
    Pago_Mensual: "8014",
  },
  {
    id: 9,
    Monto_Otorgado: "70.000",
    Monto_Depositado: "70.000",
    Cuotas: "24",
    Pago_Mensual: "7013",
  },
  {
    id: 8,
    Monto_Otorgado: "60.000",
    Monto_Depositado: "60.000",
    Cuotas: "24",
    Pago_Mensual: "6011",
  },
  {
    id: 7,
    Monto_Otorgado: "50.000",
    Monto_Depositado: "50.000",
    Cuotas: "24",
    Pago_Mensual: "5009",
  },
  {
    id: 6,
    Monto_Otorgado: "40.000",
    Monto_Depositado: "40.000",
    Cuotas: "24",
    Pago_Mensual: "4007",
  },
  {
    id: 5,
    Monto_Otorgado: "30.000",
    Monto_Depositado: "30.000",
    Cuotas: "24",
    Pago_Mensual: "3005",
  },
];

export default ListDataCredits;
