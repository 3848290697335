import React, { useState } from "react";
import "./formThree.css";
import { useNavigate } from "react-router-dom";

const FormThree = (props) => {
  const navigate = useNavigate();
  const [userThree] = useState(props.userform);
  const [UserInfo, setUserInfo] = useState({
    DNI:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.dni) ||
      "",
    Cuil:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.Cuil) ||
      "",
    Domicilio_Completo_empleador:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.Domicilio_Completo) ||
      "",
    Razon_Social:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.Razon_social) ||
      "",
    Codigo_postal:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.Codigo_postal) ||
      "",
    CBU:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.cbu) ||
      "",
    Email:
      (userThree.UserRegistration.infoUser.data &&
        userThree.UserRegistration.infoUser.data.Email) ||
      "",
  });
  const [errorForm, setErrorForm] = useState();

  const clickPartFour = () => {
    const userPass3 = {
      UserRegistration: {
        credito: { ...userThree.UserRegistration.credito },
        infoUser: { ...UserInfo },
      },
    };

    //if (errorForm === false) {

    navigate("/Subida_de_fotos", { state: userPass3 });
    //}
  };

  return (
		<div className='formThree'>
			<div className='boxflex'>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-regular fa-address-card icon'></i>
						<input
							className='inputForm'
							placeholder='DNI'
							value={UserInfo.DNI}
							maxLength={8}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									DNI: e.target.value,
								});
							}}
						/>
					</div>
					{errorForm && UserInfo.DNI?.length < 7 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-solid fa-id-badge icon'></i>
						<input
							className='inputForm'
							placeholder='Cuil'
							value={UserInfo.Cuil}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									Cuil: e.target.value,
								});
							}}
						/>
					</div>
					{errorForm && UserInfo.Cuil?.length < 11 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
			</div>

			<div className='boxflex'>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-solid fa-house-user icon'></i>
						<input
							className='inputForm'
							placeholder='Domicilio Completo / Empleador'
							value={UserInfo.Domicilio_Completo_empleador}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									Domicilio_Completo_empleador:
										e.target.value,
								});
							}}
						/>
					</div>
					{errorForm &&
					UserInfo.Domicilio_Completo_empleador?.length < 4 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
			</div>

			<div className='boxflex'>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-solid fa-people-arrows icon'></i>
						<input
							className='inputForm'
							placeholder='Razon Social'
							value={UserInfo.Razon_Social}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									Razon_Social: e.target.value,
								});
							}}
						/>
					</div>
					{errorForm && UserInfo.Razon_Social?.length < 4 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
			</div>

			<div className='boxflex'>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-solid fa-building-columns icon'></i>
						<input
							className='inputForm'
							placeholder='CBU'
							value={UserInfo.CBU}
							maxLength={22}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									CBU: e.target.value,
								});
							}}
						/>
					</div>
					{errorForm && UserInfo.CBU?.length < 22 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
			</div>

			<div className='boxflex'>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-solid fa-envelopes-bulk icon'></i>
						<input
							className='inputForm'
							placeholder='Correo Electronico'
							value={UserInfo.Email}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									Email: e.target.value,
								});
							}}
						/>
					</div>
					{errorForm && UserInfo.Email?.length < 4 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
				<div className='boxinputanderror'>
					<div className='boxInput2'>
						<i className='fa-solid fa-envelopes-bulk icon'></i>
						<input
							className='inputForm'
							placeholder='Codigo Postal'
							value={UserInfo.Codigo_postal}
							onChange={(e) => {
								setUserInfo({
									...UserInfo,
									Codigo_postal: e.target.value,
								});
							}}
						/>
					</div>
					{errorForm && UserInfo.Codigo_postal?.length < 2 ? (
						<p className='errorMesage'>Por favor llene el campo</p>
					) : null}
				</div>
			</div>

			<div className='cardForm'>
				<div></div>
				<div className='BoxInfo'>
					<span className='P-Info'>
						Monto de firma:
						<span className='GreenText'>
							$
							{
								userThree.UserRegistration.credito
									.Monto_Certificado
							}
						</span>
					</span>
					<span className='P-Info'>
						Cantidad de cuotas:
						<span className='GreenText'>
							{userThree.UserRegistration.credito.Cantidad_Cuotas}
						</span>
					</span>
					<span className='P-Info'>
						Valor de Cuota:
						<span className='GreenText'>
							${userThree.UserRegistration.credito.Pago_Mensual}
						</span>
					</span>
				</div>
			</div>

			<div className='boxButton'>
				<button onClick={clickPartFour} className='Button'>
					Siguiente
				</button>
			</div>
		</div>
  );
};

export default FormThree;
