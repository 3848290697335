import React from "react";
import ImageWall from "../components/imageWall/imageWall";
import Header from "../components/header/header";
import Canvas from "../components/SignatureCanvas/signatureCanvas";
import { useLocation } from "react-router-dom";

const CreditSix3 = () => {
  const location = useLocation();
  const userimage = location.state;

  return (
    <ImageWall>
      <Header
        title="Bienvenido a préstamo online"
        text="Mantén apretado el clic izquierdo y deslízalo sobre nuestra hoja para crear tu firma digital"
      />
      <Canvas userimage={userimage} />
    </ImageWall>
  );
};

export default CreditSix3;
