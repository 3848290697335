import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/header/header";
import ListCredit from "../components/ListCredit/ListCredit";
import ImageWall from "../components/imageWall/imageWall";
import ModalCreditList from "../components/ListCreditModal/modal";

const CreditTwo = () => {
  const location = useLocation();
  const user = location.state;
  const [state, setState] = useState(false);

  const message = (state) => {
    const creditList = state.length;
    if (creditList === 0) {
      setState(true);
    } else {
      setState(false);
    }
    return;
  };

  const changeStatus = (state) => {
    setState(state);
  };

  return (
    <ImageWall>
      {state && (
        <ModalCreditList
          message={
            "Por favor contactate via Whatsapp +54 221 548-6483, asi corroboramos tu disponible."
          }
          statusModal={changeStatus}
        />
      )}
      <Header
        title="Bienvenido a préstamo online"
        text="Seleccione uno de los siguientes préstamos haciendo clic en la opción que elija"
      />
      <ListCredit user={user} func={message} />
    </ImageWall>
  );
};

export default CreditTwo;
