import React, { useState } from "react";
import ImageWall from "../components/imageWall/imageWall";
import Header from "../components/header/header";
import PdfCreater from "../components/PDFCreater/PdfCreater";
import { useLocation } from "react-router-dom";
import Modal from "../components/modal/modal";

const CreditFinal = () => {
  const location = useLocation();
  const userfinal = location.state;
  const [modalStatus, setModalStatus] = useState({
    Message: "",
    modal: "",
  });

  const reciveProps = (message, modal) => {
    setModalStatus({ Message: message, modal: modal });
  };

  const recivemodal = (modal) => {
    setModalStatus({ Message: "", modal: modal });
  };

  return (
    <>
      <ImageWall>
        {modalStatus.modal && (
          <Modal message={modalStatus.Message} statusModal={recivemodal} />
        )}
        <Header
          title="Gracias por elegirnos a nosotros"
          text="Todos los preparativos están hechos, A la brevedad nos estaremos contactando"
        />
        <PdfCreater userinfo={userfinal} func={reciveProps} />
      </ImageWall>
    </>
  );
};

export default CreditFinal;
