import React from "react";
import logo from "../../image/logo/logo2.png";
import "./header.css";

const Header = (props) => {
  return (
    <div className="header">
      <div className="boxImage">
        <img className="image" src={logo} alt="logo" />
      </div>
      <div className="boxTittle">
        <h3 className="Tittle">{props.title}</h3>
        <p className="text">{props.text}</p>
      </div>
    </div>
  );
};

export default Header;
