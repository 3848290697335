import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { TiDelete } from "react-icons/ti";
import logoImage from "../../../image/iconCard/iconCard.png";
import "./formFive.css";

const FormFive = (props) => {
  const userFive = props.userFive;
  const [bundles, setBundles] = useState([]);
  const [formError, serFormError] = useState();
  //
  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length) {
      setBundles((previousFiles) => [
        ...previousFiles,
        ...acceptedFiles.map((file) =>
          Object.assign(file, { preview: URL.createObjectURL(file) })
        ),
      ]);
    }
  }, []);

  //SE BORRAN LOS ARCHIVOS SELECIONADOS
  const removeFile = (name) => {
    setBundles((files) => files.filter((file) => file.name !== name));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const ClickPageSix = () => {
    const user = {
      UserRegistration: {
        ...userFive.UserRegistration,
        img_user: bundles,
      },
    };

    //if (formError === false) {
    navigate("/Firma", { state: user });
    //}
  };

  return (
    <div className="cardform">
      <div className="UploadBundles" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p className="textinputbundles">Suelta los archivos aquí ...</p>
        ) : (
          <p className="textinputbundles">
            Arrastre y suelte algunos archivos aquí o haga clic para seleccionar
            archivos
          </p>
        )}
      </div>
      <div className="tittleimage">
        POR FAVOR SUBA LAS SIGUIENTES IMAGENES <br></br>Certificado - Recibo de
        Sueldo - D.N.I - Foto de Selfi - Recibo de Sueldo a su nombre
      </div>
      <div className="galleriBundles">
        <ul className="boxGalery">
          {bundles.map((file) => (
            <div className="card" key={file.name}>
              <li>
                <TiDelete
                  className="iconDeletePDF"
                  onClick={() => removeFile(file.name)}
                />
                <img
                  src={logoImage}
                  alt="logopdf"
                  className="imageGaleryBundle"
                />
              </li>
              <p className="fileNamePDF">{file.path}</p>
            </div>
          ))}
        </ul>
      </div>
      <div className="boxButton" style={{ textAlign: "center" }}>
        <button
          onClick={ClickPageSix}
          className="Button"
          style={{ cursor: "pointer" }}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default FormFive;
