import React from "react";

import ImageWall from "../components/imageWall/imageWall";
import Header from "../components/header/header";
import Form from "../components/form/formIndex/form";

const CreditFirst = () => {
  return (
    <ImageWall>
      <Header
        title="Bienvenido a préstamo online"
        text="Para iniciar el proceso por favor ingrese los datos pedidos en pantalla"
      />
      <Form />
    </ImageWall>
  );
};

export default CreditFirst;
