import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import datacredit from "../../data/dataCredit";
import "./ListCredit.css";

const ListCredit = (props) => {
  const [User] = useState(props.user);
  const navigate = useNavigate();

  const NewTotal = User.UserRegistration.credito.Monto_Certificado - 4175;

  // eslint-disable-next-line array-callback-return
  const List = datacredit.filter((data) => {
    if (parseInt(data.Pago_Mensual) < parseInt(NewTotal)) return data;
  });

  const CachValue = (event, element) => {
    event.preventDefault();
    const userPass2 = {
      UserRegistration: {
        infoUser: { ...User.UserRegistration.infoUser },
        credito: {
          ...User.UserRegistration.credito,
          Monto_Otorgado: element.Monto_Otorgado,
          Monto_Depositado: element.Monto_Depositado,
          Cantidad_Cuotas: element.Cuotas,
          Pago_Mensual: element.Pago_Mensual,
        },
      },
    };
    navigate("/3dejunio/Confirmacion_Info", { state: userPass2 });
  };

  const listItems = List.map((element) => {
    return (
      <div
        key={element.id}
        className="cardCredit3"
        onClick={(event) => CachValue(event, element)}
      >
        <p className="TextCredit3">Monto Otorgado: ${element.Monto_Otorgado}</p>
        <p className="TextCredit3">
          Monto Depositado: ${element.Monto_Depositado}
        </p>
        <p className="TextCredit3">Cantidad Cuotas: {element.Cuotas}</p>
        <p className="TextCredit3">Pago Mensual: ${element.Pago_Mensual}</p>
      </div>
    );
  });

  return <div className="scroll3">{listItems}</div>;
};

export default ListCredit;
