import React from "react";
import ImageWall from "../components/imageWall/imageWall";
import Header from "../components/header/header";
import FormThree from "../components/form/formThree/formThree";
import { useLocation } from "react-router-dom";

const PageThree3 = () => {
  const location = useLocation();
  const user = location.state;
  return (
    <ImageWall>
      <Header
        title="Bienvenido a préstamo online"
        text="Verifique o complete los datos que aparecen en el formulario a continuación"
      />
      <FormThree userform={user} />
    </ImageWall>
  );
};

export default PageThree3;
