import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import env from "react-dotenv";
import "./PdfCreater.css";
import axios from "axios";

const PdfCreater = (props) => {
  const navigate = useNavigate();
  const [loader, setloader] = useState(false);
  const [showbutton, setShowbutton] = useState(true);
  const userfinal = props.userinfo;
  const [user] = useState({
    dni: userfinal.UserRegistration.infoUser.DNI,
    cuil: userfinal.UserRegistration.infoUser.Cuil,
    domicilio_empleador:
      userfinal.UserRegistration.infoUser.Domicilio_Completo_empleador,
    razon_social: userfinal.UserRegistration.infoUser.Razon_Social,
    cbu: userfinal.UserRegistration.infoUser.CBU,
    email: userfinal.UserRegistration.infoUser.Email,
    monto_certificado: userfinal.UserRegistration.credito.Monto_Certificado,
    monto_otorgado: userfinal.UserRegistration.credito.Monto_Otorgado,
    monto_depositado: userfinal.UserRegistration.credito.Monto_Depositado,
    cantidad_cuotas: userfinal.UserRegistration.credito.Cantidad_Cuotas,
    pago_mensual: userfinal.UserRegistration.credito.Pago_Mensual,
  });

  const saveUser = async (usersendsave) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const statuspdf = await axios.post(
        env.REACT_APP_CREATEUSER,
        usersendsave
      );
    } catch (err) {}
  };

  const saveUserAndSendEmail = async (usersendsave) => {
    try {
      setShowbutton(false);
      setloader(true);
      const statuspdf = await axios.post(
        env.REACT_APP_CREATEUSER,
        usersendsave
      );

      if (statuspdf.status === 200) {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        const formData = new FormData();
        for (
          let i = 0;
          i < usersendsave.UserRegistration.img_user.length;
          i++
        ) {
          formData.append("images", usersendsave.UserRegistration.img_user[i]);
        }

        const responseSendEmail = await axios.post(
          env.REACT_APP_SENDEMAIL,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        setloader(false);
        setShowbutton(true);
        if (responseSendEmail.data === "OK") {
          props.func(
            "El envio fue exitoso, a la brevedad un operador estara contactandote.",
            true
          );
        } else {
          props.func(
            "El envio no fue exitoso, te pedimos, por favor, que reintentes nuevamente",
            true
          );
        }
      }
    } catch (err) {
      setloader(false);
      setShowbutton(true);
    }
  };

  const sendpdf = async (e) => {
    e.preventDefault();
    const userSend = {
      UserRegistration: {
        ...userfinal.UserRegistration,
        se_envio_pdf: {
          Consulta: "enviada",
          Envio_pdf: "enviado",
        },
      },
    };

    saveUserAndSendEmail(userSend);
    //CAMBIR INFORMACION TOTAL POR RUTAS DE IMAGENES
  };

  const sendMenu = (e) => {
    e.preventDefault();
    const userSend = {
      UserRegistration: {
        ...userfinal.UserRegistration,
        se_envio_pdf: {
          Consulta: "no enviada",
          Envio_pdf: "no enviado",
        },
      },
    };
    saveUser(userSend);
    navigate("/3dejunio");
  };

  return (
    <div className="formCard3">
      <div className="flexheigpc3">
        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-regular fa-address-card icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="DNI"
              name="dni"
              defaultValue={user.dni}
              disabled={true}
            />
          </div>
        </div>

        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-id-badge icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Cuil"
              name="cuil"
              defaultValue={user.cuil}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="flexheigpc3">
        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-house-user icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Domicilio Completo / Empleador"
              name="domicilio_empleador"
              defaultValue={user.domicilio_empleador}
              disabled={true}
            />
          </div>
        </div>

        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-people-arrows icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Razon Social"
              name="razon_social"
              defaultValue={user.razon_social}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="flexheigpc3">
        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-building-columns icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="CBU"
              name="cbu"
              defaultValue={user.cbu}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="flexheigpc3">
        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-envelopes-bulk icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Correo Electronico"
              name="email"
              defaultValue={user.email}
              disabled={true}
            />
          </div>
        </div>

        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-dollar-sign icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Monto Certificado"
              name="monto_certificado"
              defaultValue={user.monto_certificado}
              disabled={true}
            />
          </div>
        </div>

        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-dollar-sign icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Monto Otorgado"
              name="monto_otorgado"
              defaultValue={user.monto_otorgado}
              disabled={true}
            />
          </div>
        </div>
      </div>

      <div className="flexheigpc3">
        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-dollar-sign icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Monto Depositado"
              name="monto_depositado"
              defaultValue={user.monto_depositado}
              disabled={true}
            />
          </div>
        </div>

        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-dollar-sign icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Pago Mensual"
              name="cantidad_cuotas"
              defaultValue={user.pago_mensual}
              disabled={true}
            />
          </div>
        </div>

        <div className="boxInputFlex3">
          <div className="boxicon3">
            <i className="fa-solid fa-calendar-days icon"></i>
          </div>
          <div className="boxinput3">
            <input
              type="text"
              className="inputForm3"
              placeholder="Cantidad de cuotas"
              name="pago_mensual"
              defaultValue={user.cantidad_cuotas}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="boxButton63">
        {showbutton && (
          <>
            <button onClick={sendMenu} className="Button63">
              Volver al Menú
            </button>
            <button onClick={sendpdf} className="Button63">
              Enviar
            </button>
          </>
        )}
        {loader && <span class="loader"></span>}
      </div>
    </div>
  );
};

export default PdfCreater;
